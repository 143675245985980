import React from "react";
import PropTypes from "prop-types";
import { graphql, PageProps } from "gatsby";
import { InlineWidget } from "react-calendly";

import remark from "remark";

const buttonClassNames =
  "text-gray-500 rounded hover:font-medium font-bold py-2 px-2 mx-4";

export const IndexPageTemplate: React.FC<{
  image: any;
  body: string;
  location: PageProps["location"]
}> = ({ body, image, location }) => { 
  let params = new URLSearchParams(location.search);
  return (
  <div className="w-full" style={{ background: "rgb(248, 249, 252)" }}>
    <nav className="shadow-lg">
      <div className="container px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto">
        <div className="md:flex items-center justify-between py-2 px-2 md:px-4">
          <div>
            <a href="#">
              <img src="https://www.scalexp.com/wp-content/uploads/2021/01/logo.png" />
            </a>
          </div>
          <div className="flex flex-col md:flex-row md:block -mx-2">
            <a href="https://www.scalexp.com/" className={buttonClassNames}>
              Home
            </a>
            <a
              href="https://www.scalexp.com/features/"
              className={buttonClassNames}
            >
              Features
            </a>
            <a
              href="https://www.scalexp.com/benefits/"
              className={buttonClassNames}
            >
              Benefits
            </a>
            <a
              href="https://www.scalexp.com/about-us/"
              className={buttonClassNames}
            >
              About Us
            </a>
            <a
              href="https://www.scalexp.com/blog/"
              className={buttonClassNames}
            >
              Blog
            </a>
            <a
              href="https://www.scalexp.com/contact-us/"
              className={buttonClassNames}
            >
              Contact Us
            </a>
            <a
              target="_blank"
              href="http://app.scalexp.com/"
              className="text-white font-bold rounded py-3 px-8 mx-4"
              style={{ background: "#355570" }}
            >
              Login
            </a>
          </div>
        </div>
      </div>
    </nav>
    <div className="hero bg-gray-100">
      <div className="container px-4 sm:px-8 lg:px-16 xl:px-20 mx-auto">
        <div className="hero-wrapper grid grid-cols-1 md:grid-cols-12 gap-8 items-center">
          <div className="hero-text col-span-6">
            <div
              className="prose"
              dangerouslySetInnerHTML={{ __html: body }}
            ></div>
            <img
              src={
                !!image.childImageSharp
                  ? image.childImageSharp.fluid.src
                  : image
              }
            />
          </div>
          <div className="col-span-6">
            <InlineWidget
              url="https://calendly.com/scalexp/scalexp-onboarding"
              styles={{ minWidth: "100%", height: "900px" }}
              prefill={{
                firstName: params.get("first_name") || "",
                lastName: params.get("last_name") || "",
                email: params.get("email") || "",
                name: `${params.get("first_name") || ""} ${params.get("last_name") || ""}`
              }}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)};

const IndexPage = ({ data, location }) => {
  const { frontmatter, html } = data.markdownRemark;

  return <IndexPageTemplate body={html} image={frontmatter.image} location={location} />;
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
